import './src/styles/reset.scss';
import './src/styles/global.scss';
import '@fontsource/poppins';

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  // Don't scroll to top when changing filters in params on work page
  if (location.hash === '') {
    window.scrollTo(0, 0);
  }

  return false;
};
